$(document).on('turbolinks:load', () => {

  $('#inquiry_submit').prop('disabled', true);

  $('#inquiry_agree').on('click', function() {
    if ( $(this).prop('checked') == false ) {
      $('#inquiry_submit').prop('disabled', true);
    } else {
      $('#inquiry_submit').prop('disabled', false);
    }
  });
})