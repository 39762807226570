import $ from 'jquery';
import 'slick-carousel';

window.addEventListener('turbolinks:load', () => {
  $('.content__images-carousel').slick({
    dots: true,
    infinite: true,
    speed: 500,
    responsive:[
      {
        breakpoint: 769,
        settings:{
          arrows: false,
        }
      },
    ]  
  });
});