$(document).on('turbolinks:load', () => {

  console.log(window.location.search)
  if (window.location.search) {
    $('.search').css({ display: 'block' })
  } else {
    $('.search').css({ display: 'none' })
  }

  $('#category_tag_list').tagsinput({
    tagClass: 'search__tag'
  });

  $('#category_tag_list').on('itemAdded', (event) => {
    console.log(event.item)

    window.location.search = "tags=" + $('#category_tag_list').val()
  });

  $('#category_tag_list').on('itemRemoved', (event) => {
    console.log(event.item)

    if ($('#category_tag_list').val()) {
      window.location.search = "tags=" + $('#category_tag_list').val()
    } else {
      window.location.search = ""
    }
  });

  $('.title__search').on('click', (event) => {

    if ($('.search').css('display') == 'none') {
      $('.search').fadeIn()
    } else {
      $('.search').fadeOut()
    }
  })

  $('.search__recommends-tag').on('click', (event) => {
    $('#category_tag_list').tagsinput('add', $(event.currentTarget).text());
  })
})